export class Box {

  id: string;
  price: number;
  sku: string;
  name: string;
  default: boolean;
  available: boolean;
  items: any;
  createdAt: Date;
  updatedAt: Date;

  constructor(data) {
    this.id =  data._id;
    this.price =  data.price;
    this.sku =  data.sku;
    this.name =  data.name;
    this.default =  data.default;
    this.available =  data.available;
    this.createdAt =  data.createdAt;
    this.updatedAt =  data.updatedAt;
    this.items = data.items;
  }
}
