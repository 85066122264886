export class Payment {
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  subscription: number;
  createdAtPt: string;
  refunded: boolean;
  rejected: boolean;
  success: boolean;
  amount: number;
  processor = 'braintree';
  transactionId: string;

  constructor(data) {
    Object.assign(this, data);
  }
}
