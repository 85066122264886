import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewRef
} from '@angular/core';

import * as client from 'braintree-web/client';
import * as hostedFields from 'braintree-web/hosted-fields';

// models
import { PaymentMethod } from '@app/models/payment-method.model';
import { Customer } from '@app/models/customer.model';

import { BraintreeService } from '@app/core/services';
import { CustomerService } from '@app/services';
import { FormControl, Validators } from '@angular/forms';
import { environment } from '@env/environment';


import Swal from 'sweetalert2';
import { AlertService } from '@app/shared/components/alerts/alert.service';

@Component({
  selector: 'app-payment-methods-modal',
  templateUrl: './payment-methods-modal.component.html',
  styleUrls: ['./payment-methods-modal.component.scss']
})
export class PaymentMethodsModalComponent implements OnInit, AfterViewInit {

  @Input() customer: Customer;
  @Input() paymentMethods: PaymentMethod[];

  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  clientToken: string;
  dropInInstance = null;
  showNewPaymentMethod: boolean;
  selectedPaymentMethod: PaymentMethod;

  canSubmitPayment = false;
  isFocus = false;

  isFormValid = false;
  isClientInitialized = false;

  isNumberValid: boolean;
  isCvvValid: boolean;
  isExpDateValid: boolean;

  showErrorMessages = false;
  showTokenizeError = false;
  isNumberTouched = false;
  isCvvTouched = false;
  isExpDateTouched = false;

  submitBtn: HTMLButtonElement;
  hostedFields: any;

  cardholderNameControl = new FormControl('', Validators.required);

  fields = {
    number: {
      selector: '#card-number',
      placeholder: 'Card Number'
    },
    cvv: {
      selector: '#cvv',
      placeholder: '123'
    },
    expirationDate: {
      selector: '#expiration-date',
      placeholder: '12/2019'
    }
  };

  styles = {
    'input': {
      'font-size': '14px',
      'font-family': '"Omnes Regular", Helvetica, sans-serif',
      'font-weight': '300',
      'margin-top': '5px'
    },
    '.input-error::placeholder': {
      'color': 'red'
    }
  };

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() updated: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private alertService: AlertService,
    private customerService: CustomerService,
    private braintreeService: BraintreeService,
    private changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.submitBtn = document.getElementById('update-btn') as HTMLButtonElement;
  }

  onAddPaymentMethod() {
    this.showNewPaymentMethod = true;
    this.selectedPaymentMethod = undefined;
    this.initializeCreditCardForm();
  }

  onEditPaymentMethod(paymentMethod: PaymentMethod) {
    this.showNewPaymentMethod = false;
    this.selectedPaymentMethod = paymentMethod;
    this.initializeCreditCardForm();
  }

  markAsDefaultPaymentMethod(paymentMethod: PaymentMethod) {
    this.customerService
      .defaultPaymentMethod({ customerId: this.customer.id, paymentMethodId: paymentMethod.id })
      .subscribe((response) => {
        this.paymentMethods = response.paymentMethods;
        this.detectChanges();
      });
  }

  initializeCreditCardForm() {
    this.initializeBraintreeClientSDK();
  }

  onDeletePaymentMethod(paymentMethod: PaymentMethod) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.customerService
          .deletePaymentMethod({ customerId: this.customer.id, paymentMethodId: paymentMethod.id })
          .subscribe((response) => {
            this.paymentMethods = response.paymentMethods;
            this.detectChanges();
          }, (error) => {
            this.alertService.danger({
              alertsCode: 'dashboard-alerts',
              title: `Can't delete payment method`,
              message: error.error && error.error.message || error.message,
              timeout: 5000
            });
          });
      }
    });
  }

  addNewPaymentMethod() {
    if (!this.isFormValid || this.cardholderNameControl.invalid) {
      this.showErrorMessages = true;
      this.checkAndAddInputErrorClass();
      return;
    }

    this.isClientInitialized = false;
    this.hostedFields.tokenize()
      .then(payload => {
        this.showErrorMessages = false;
        this.showTokenizeError = false;
        return this.customerService
          .addPaymentMethod({
            customerId: this.customer.id,
            nonce: payload.nonce,
            cardholderName: this.cardholderNameControl.value
          })
          .toPromise()
          .then((response) => {
            this.paymentMethods = response.paymentMethods;
            this.cancelEditing();
            this.isClientInitialized = false;
          }, (error) => {
            this.alertService.danger({
              alertsCode: 'dashboard-alerts',
              title: `Can't add the payment method`,
              message: error.error && error.error.message || error.message,
              timeout: 5000
            });
          });
      })
      .catch(err => {
        this.showErrorMessages = true;
        this.showTokenizeError = true;
        this.checkAndAddInputErrorClass();
      });
  }

  updatePaymentMethod() {
    if (!this.isFormValid || this.cardholderNameControl.invalid) {
      this.showErrorMessages = true;
      this.checkAndAddInputErrorClass();
      return;
    }

    this.isClientInitialized = false;
    this.hostedFields.tokenize()
      .then(payload => {
        this.showErrorMessages = false;
        this.showTokenizeError = false;
        return this.customerService
          .updatePaymentMethod({
            customerId: this.customer.id,
            nonce: payload.nonce,
            paymentMethodId: this.selectedPaymentMethod.id,
            cardholderName: this.cardholderNameControl.value,
          })
          .toPromise()
          .then(() => {
            this.cancelEditing();
            this.closeModal();
            this.isClientInitialized = true;
          });
      })
      .catch(err => {
        this.showErrorMessages = true;
        this.showTokenizeError = true;
        this.checkAndAddInputErrorClass();
      });
  }

  /**
   * Initialize the Braintree Client sdk.
   * This methods needs to be called every time the
   * payment form is in edit mode (is not saved) in order
   * to re-initialize the client sdk with the new data.
   *
   * @memberof SecurePaymentFormComponent
   */
  async initializeBraintreeClientSDK() {
    this.isClientInitialized = false;
    const clientToken = await this.braintreeService.getClientAuthorizationToken();
    client
      .create({ authorization: clientToken })
      .then((clientInstance: any) => hostedFields.create({
        client: clientInstance,
        fields: this.fields,
        styles: this.styles
      }))
      .then(hostedFieldsInstance => {
        this.isClientInitialized = true;
        this.hostedFields = hostedFieldsInstance;
        this.hostedFields.on('validityChange', e => {
          const state = this.hostedFields.getState();
          const formValid = Object
            .keys(state.fields)
            .every((key) => {
              this.isCvvValid = state.fields.cvv.isValid;
              this.isNumberValid = state.fields.number.isValid;
              this.isExpDateValid = state.fields.expirationDate.isValid;
              return state.fields[key].isValid;
            });
          this.isFormValid = formValid;
        });

        this.hostedFields.on('focus', e => {
          switch (e.emittedBy) {
            case 'number': {
              this.isNumberTouched = e.fields.number.isFocused ? true : this.isNumberTouched;
              break;
            }
            case 'cvv': {
              this.isCvvTouched = e.fields.cvv.isFocused ? true : this.isCvvTouched;
              break;
            }
            case 'expirationDate': {
              this.isExpDateTouched = e.fields.expirationDate.isFocused ? true : this.isExpDateTouched;
              break;
            }
          }
        });

      })
      .catch(e => console.log(e));
  }

  checkAndAddInputErrorClass() {
    if (!this.isNumberValid && this.isNumberTouched && this.showErrorMessages) {
      this.hostedFields.clear('number');
      this.hostedFields.addClass('number', 'input-error');
    }
    if (!this.isCvvValid && this.isCvvTouched && this.showErrorMessages) {
      this.hostedFields.clear('cvv');
      this.hostedFields.addClass('cvv', 'input-error');
    }
    if (!this.isExpDateValid && this.isExpDateTouched && this.showErrorMessages) {
      this.hostedFields.clear('expirationDate');
      this.hostedFields.addClass('expirationDate', 'input-error');
    }
  }

  cancelEditing() {
    this.selectedPaymentMethod = null;
    this.isClientInitialized = null;
    this.showNewPaymentMethod = null;
    this.detectChanges();
  }

  closeModal() {
    this.close.emit();
  }

  private detectChanges() {
    if (this.changeDetector && !(this.changeDetector as ViewRef).destroyed) {
      this.changeDetector.detectChanges();
    }
  }
}
