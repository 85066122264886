import { Injectable } from '@angular/core';
import { connect } from 'socket.io-client';
import { environment } from '@env/environment';

import {
  Observable,
  Subscriber
} from 'rxjs';

@Injectable()
export class SocketIoService {
  private socket;
  socketId: string;

  constructor() {
    this.socket = connect(environment.apiUrl);

    this.socket.on('connect', () => {
      this.socketId = this.socket.io.engine.id;
    });
  }

  listen(event: string): Observable<any> {
    const observable = new Observable((observer: Subscriber<any>) => {
      this.socket.on(event, data => observer.next(data));

      // return () => this.socket.disconnect();
    });

    return observable;
  }

  emit(event: string, data?: any) {
    this.socket.emit(event, data);
  }
}
