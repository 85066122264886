<div
  role="dialog"
  class="show modal fade"
  style="display: block; background-color: rgba(0, 0, 0, .8);"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Payment Methods</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
          #closeBtn
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="payment-methods-container">

          <ul class="list-group" *ngIf="!showNewPaymentMethod && !selectedPaymentMethod">
            <li
              *ngFor="let paymentMethod of paymentMethods"
              class="list-group-item d-flex justify-content-between align-items-center">
              <p style="margin: 0;"><span class="font-weight-bold">{{ paymentMethod.cardType }}</span> ending in <span
                class="font-weight-bold">{{ paymentMethod.last4 }}</span> <span
                class="font-italic"> {{ paymentMethod.expirationDate }}</span></p>
              <div class="text-right">
<!--                <button (click)="onEditPaymentMethod(paymentMethod)" type="button" class="btn btn-primary btn-sm mr-1">-->
<!--                  Edit-->
<!--                </button>-->
                <button
                  [disabled]="paymentMethod.default"
                  (click)="markAsDefaultPaymentMethod(paymentMethod)" type="button" class="btn btn-primary btn-sm mr-1">
                  Mark as Default
                </button>
                <button (click)="onDeletePaymentMethod(paymentMethod)" type="button" class="btn btn-danger btn-sm ml-1">
                  Delete
                </button>
              </div>
            </li>
          </ul>

          <div class="mt-3 text-center" *ngIf="!isClientInitialized && !showNewPaymentMethod && !selectedPaymentMethod">
            <button (click)="onAddPaymentMethod()" type="button" class="btn btn-primary">
              Add Payment Method
            </button>
          </div>

          <div class="payment-method-update" *ngIf="selectedPaymentMethod || showNewPaymentMethod">

            <p class="selected-payment-method-text" *ngIf="selectedPaymentMethod">
              Editing {{ selectedPaymentMethod.cardType }} ending in {{ selectedPaymentMethod.last4 }}
            </p>
            <p class="selected-payment-method-text" *ngIf="addNewPaymentMethod">
              Adding New Payment Method
            </p>

            <div class="form-loader" *ngIf="!isClientInitialized">
              <img src="/assets/images/loader.svg" alt="">
            </div>

            <!-- Cardhodler's Name -->
            <div class="form-group form-group__flex-start form-group--carddolder-name">
              <input
                #nameInput
                type="text"
                autocomplete="off"
                [formControl]="cardholderNameControl"
                placeholder="Cardholder's name"
                class="form-group__input capital-case-input"
              >
              <span class="error-text"
                    *ngIf="cardholderNameControl.invalid && cardholderNameControl.touched && showErrorMessages">
                Provide the cardholder's name
              </span>
            </div>

            <div class="payment-method-form-container" [ngClass]="{ 'form-loader-opacity': !isClientInitialized }">
              <div class="form-group form-group__flex-start">
                <div id="card-number" class="form-group__input"></div>
                <span class="error-text" *ngIf="!isNumberValid && isNumberTouched && showErrorMessages">
                  You need to write a valid credit card number
                </span>
              </div>

              <div class="card-info-group">
                <div class="form-group card-info__date form-group__flex-start">
                  <label for="expiration-date" class="form-group__input">
                    <span class="hosted-label">Expiration Date</span>
                    <div id="expiration-date"></div>
                  </label>
                  <span class="error-text" *ngIf="!isExpDateValid && isExpDateTouched && showErrorMessages">
                    Invalid expiration date
                  </span>
                </div>

                <div class="form-group card-info__cvv form-group__flex-start">
                  <label for="cvv" class="form-group__input">
                    <span class="hosted-label">CVV</span>
                    <div id="cvv"></div>
                  </label>
                  <span class="error-text" *ngIf="!isCvvValid && isCvvTouched && showErrorMessages">
                    Invalid cvv
                  </span>
                </div>
              </div>
            </div>
            <div class="payment-method-form-controls"
                 *ngIf="(selectedPaymentMethod|| showNewPaymentMethod) && isClientInitialized">
              <button *ngIf="showNewPaymentMethod" (click)="addNewPaymentMethod()" id="update-btn" type="button"
                      class="btn btn-primary btn-sm">Save
              </button>
              <button *ngIf="selectedPaymentMethod" (click)="updatePaymentMethod()" id="payButton" type="button"
                      class="btn btn-primary btn-sm">UPDATE
              </button>
              <button (click)="cancelEditing()" type="button" class="btn btn-danger btn-sm">CANCEL</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="closeModal()" type="button" class="btn btn-danger">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
