import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';

// Models

@Injectable()
export class RateService {
  private apiUrl = environment.apiUrl + '/api';

  constructor(private http: HttpClient) {
  }

  public getStates(): Observable<any> {
    return this.http.get(`${this.apiUrl}/admin/rates`);
  }

  public savePreferredShipper(data): Observable<any> {
    return this.http.put(`${this.apiUrl}/admin/rates`, data);
  }


}
