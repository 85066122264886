import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManifestsService {

  private apiUrl = environment.apiUrl + '/api';

  constructor(
    private http: HttpClient
  ) { }

  getTodaysManifests(): Observable<IManifest[]> {
    return this.http.get<{data: IManifest[]}>(`${this.apiUrl}/manifests/today`)
      .pipe(pluck('data'));
  }
}

export interface IManifest {
  createdAt: string;
  downloadLink: string;
}
